<template>
  <div class="relative min-h-[2.5rem] grow">
    <div class="right-0 top-0" :class="!searchIsExpanded ? 'absolute' : 'fixed z-50 bg-white p-[1rem] grid grid-cols-3'" :style="{ width: !searchIsExpanded ? '100%' : '100vw', minHeight: !searchIsExpanded ? '0px' : '150px' }" style="transition: width 250ms ease-in-out, height 250ms ease-in-out">
      <div v-if="searchIsExpanded" class="hidden min-[781px]:block">
        <div class="navigation-logo text-start">
          <img class="mb-[5px] h-[1.75rem]" src="/logo.svg" alt="ZEIT Shop Logo"/>
          <div class="block font-featured text-[13px] font-[600]">Besondere Ideen, erlesene Geschenke</div>
        </div>
      </div>
      <div class="relative col-span-2 h-[2.5rem] min-[781px]:col-span-1">
        <input
            ref="searchInputElement"
            v-model="searchInput"
            class="peer h-[2.5rem] w-full pl-[2.75rem] pr-[0.75rem] font-[500] outline-none"
            :class="[ (hasPillShape || searchIsExpanded) ? 'rounded-full' : null ]"
            :style="{ backgroundColor: !searchIsExpanded ? backgroundColor : '#F2F2F2' }"
            type="text"
            autocapitalize="none"
            autocomplete="off"
            @keyup.enter="navigateToSearchResults"
        />
        <label class="absolute left-[2.75rem] top-1/2 -translate-y-1/2 text-[0.75rem] font-[600] text-[#222222] peer-focus:hidden" :class="(searchIsExpanded && searchInput !== null) ? 'hidden' : null" @click="focusSearchInputElement">
          {{ t('zeit_storefront.layout.header.actions.search') }}
        </label>
        <svg class="absolute left-[0.75rem] top-1/2 -translate-y-1/2" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" @click="focusSearchInputElement">
          <path d="M16.6663 16.6668L13.6463 13.6468M13.6463 13.6468C12.8014 14.4918 11.7253 15.0676 10.5533 15.3007C9.38138 15.5339 8.1666 15.4142 7.06263 14.9569C5.95866 14.4997 5.01508 13.7253 4.35121 12.7317C3.68735 11.7382 3.33301 10.5701 3.33301 9.37516C3.33301 7.77281 3.96954 6.23609 5.10257 5.10306C6.2356 3.97003 7.77232 3.3335 9.37467 3.3335C10.5696 3.3335 11.7377 3.68783 12.7312 4.3517C13.7248 5.01557 14.4992 5.95915 14.9564 7.06312C15.4137 8.16709 15.5334 9.38186 15.3003 10.5538C15.0671 11.7258 14.4913 12.8019 13.6463 13.6468Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <button
            v-if="searchInput !== null"
            class="absolute right-0 top-1/2 flex size-[2.5rem] -translate-y-1/2 items-center justify-center rounded-full hover:bg-[#D9D9D9]"
            @click="clearSearch"
        >
          <svg aria-hidden="true" class="pre-nav-design-icon" focusable="false" viewBox="0 0 24 24" role="img" width="24px" height="24px" fill="none">
            <path stroke="currentColor" stroke-width="1.5" d="M18.973 5.027L5.028 18.972M5.027 5.027l13.945 13.945"></path>
          </svg>
        </button>
      </div>
      <div v-if="searchIsExpanded" class="flex items-start justify-end">
        <button class="block cursor-pointer items-center" @click="closeExpandedSearch">
          <svg class="mx-auto mb-[-0.25rem] h-[1.5rem]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
            <path d="M192 233.4L59.5 100.9 36.9 123.5 169.4 256 36.9 388.5l22.6 22.6L192 278.6 324.5 411.1l22.6-22.6L214.6 256 347.1 123.5l-22.6-22.6L192 233.4z"/>
          </svg>
          <div class="text-[0.625rem] font-[600]">{{ t('zeit_storefront.layout.header.close') }}</div>
        </button>
      </div>

      <div v-if="searchIsExpanded && searchIsLoading" class="col-span-3 my-[2rem] grid grid-cols-2 gap-[0.5rem] transition-all min-[501px]:grid-cols-3 min-[601px]:grid-cols-4 min-[781px]:grid-cols-5">
        <ProductBoxLoading/>
        <ProductBoxLoading/>
      </div>
      <div v-else-if="searchIsExpanded && !searchIsLoading && searchResults.length > 0" class="col-span-3 my-[2rem] grid max-h-[calc(100vh-5.5rem)] grid-cols-2 gap-[0.5rem] overflow-y-scroll pb-[1.5rem] transition-all min-[501px]:grid-cols-3 min-[601px]:grid-cols-4 min-[781px]:grid-cols-5">
        <a v-for="product in searchResults" :href="getSeoUrlFromProduct(product)" class="block">
          <ProductBox v-bind="product"/>
        </a>
        <a :href="`/suche?q=${encodeURIComponent(searchInput)}`">
          <NavigationSearchShowAllBox :total-results-count="searchResultsCount"/>
        </a>
      </div>
      <div v-else-if="searchIsExpanded && !searchIsLoading && searchResults.length === 0" class="col-span-3 my-[2rem] flex items-center justify-center transition-all">
        <div>{{ t('zeit_storefront.components.search.no_results') }}</div>
      </div>
    </div>
  </div>

  <div
      v-if="searchIsExpanded"
      class="fixed inset-0 z-40 h-[100vh] w-[100vw] bg-black"
      :style="{ opacity: !searchIsExpanded ? '0' : '.25' }"
      style="transition: opacity 300ms ease-in-out;transition-delay: 150ms;"
      @click="closeExpandedSearch"
  ></div>
</template>

<script setup>
import { useTranslations } from "@i18n/utils.js";
import { useProduct, getSeoUrlFromProduct } from "@shopware/product/index.js";
import { ref, watch } from "vue";
import ProductBox from "@components/category/ProductBox.vue";
import ProductBoxLoading from "@components/category/ProductBoxLoading.vue";
import NavigationSearchShowAllBox from "@components/search/NavigationSearchShowAllBox.vue";
import {throttle} from "@shopware/helper/event/index.js";

const { t } = useTranslations();
const { searchSuggestProduct } = useProduct();

const props = defineProps({
  hasPillShape: {
    type: Boolean,
    default: true,
  },
  backgroundColor: {
    type: String,
    default: '#F2F2F2'
  }
});

const searchInputElement = ref(null);
const searchInput = ref(null);
const searchIsExpanded = ref(false);
const searchIsLoading = ref(false);
const searchResults = ref([]);
const searchResultsCount = ref(0);
const currentSearchId = ref(0);

watch(searchInput, throttle(async (searchVal, oldSearchVal) => {
  if (searchVal === oldSearchVal) return;

  if (searchVal.replace(/ /g, '').length === 0) {
    searchInput.value = null;
    searchVal = null;

    return;
  }

  // Store and increase the counter to display the most recent result only
  const current = currentSearchId.value + 1;
  currentSearchId.value = current;


  // To prevent flickering, display the loading only if there are no results yet
  if (searchResults.value.length === 0) {
    searchIsLoading.value = true;
  }

  searchIsExpanded.value = true;

  searchSuggestProduct(searchVal)
      .then(results => {
        if (current !== currentSearchId.value) {
          // There is a more recent search ongoing
          return;
        }

        searchResults.value = results.slice(0, 4);
        searchResultsCount.value = results.length - 4;
        searchIsLoading.value = false;
      })
      .catch(error => {
        console.error(error);
        searchIsLoading.value = false;
      });


  document.body.style.overflow = 'hidden';
}, 200));

const closeExpandedSearch = () => {
  searchInput.value = null;
  searchIsExpanded.value = false;
  document.body.style.overflow = 'auto';
};

const clearSearch = () => {
  searchInput.value = null;
  searchResults.value = [];
};

const focusSearchInputElement = () => {
  searchInputElement.value?.focus();
};

const navigateToSearchResults = () => {
  if (typeof searchInput.value !== 'string' || searchInput.value.replace(/ /g, '').length === 0) {
    return;
  }

  window.location = `/suche?q=${encodeURIComponent(searchInput.value)}`
};
</script>