<template>
  <div>
    <div class="aspect-square min-w-full animate-pulse bg-[#D9D9D9]"></div>
    <div class="flex flex-col space-y-[0.5rem] bg-white pt-[1.25rem]">
      <div>
        <div class="mb-[0.375rem] h-[0.875rem] animate-pulse bg-[#D9D9D9]"></div>
        <div class="mb-[0.375rem] h-[0.875rem] animate-pulse bg-[#D9D9D9]"></div>
        <div class="mt-[1.5rem] h-[0.875rem] w-[5rem] animate-pulse bg-[#D9D9D9]"></div>
      </div>
    </div>
  </div>
</template>

<script setup>
</script>