<template>
  <div>
    <div class="group flex aspect-square w-full cursor-pointer items-center justify-center bg-[#F2F2F2] transition-all hover:bg-[#222222]">
      <div class="flex flex-col items-center justify-center gap-y-[1rem]">
        <svg style="transform: scale(200%)" width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1.5 4.5H9.5" class="stroke-[#000000] transition-all group-hover:stroke-white" stroke-width="1.2" stroke-linecap="square" stroke-linejoin="round"/>
          <path d="M7.5 7.5L10.5 4.5L7.5 1.5" class="stroke-[#000000] transition-all group-hover:stroke-white" stroke-width="1.2" stroke-linecap="square"/>
        </svg>
        <div class="text-[1.25rem] font-[500] transition-all group-hover:text-[#FFFFFF]">{{ totalResultsCount > 0 ? totalResultsCount : '' }} {{ t('zeit_storefront.components.search.further_results') }}</div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {useTranslations} from "@i18n/utils.js";

const {t} = useTranslations();

const props = defineProps({
  totalResultsCount: {
    type: Number,
    default: 0
  }
});

</script>